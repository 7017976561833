import { Notification, NotificationProps } from '@commercefoundation/fractal';
import { PropsWithChildren, createContext, useEffect, useState } from 'react';

type NotificationProviderProps = Omit<NotificationProps, 'open' | 'onClose'>;

export const NotificationContext = createContext<{
  showNotification: (value: NotificationProviderProps) => void;
  hideNotification: () => void;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  showNotification: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  hideNotification: () => {},
});

export const NotificationProvider = ({ children }: PropsWithChildren) => {
  const [notification, setNotification] = useState<NotificationProps | null>(null);

  const showNotification = (value: NotificationProviderProps) => {
    setNotification({
      open: true,
      onClose: hideNotification,
      ...value,
    });
  };

  const hideNotification = () => {
    setNotification(null);
  };

  useEffect(() => {
    let timer: number | NodeJS.Timeout;

    if (notification) {
      timer = setTimeout(() => {
        hideNotification();
      }, notification.autoHideDuration || 5000);
    }

    return () => clearTimeout(timer);
  }, [notification]);

  return (
    <NotificationContext.Provider value={{ showNotification, hideNotification }}>
      {children}
      {notification && <Notification {...notification} />}
    </NotificationContext.Provider>
  );
};

export const showErrorNotification = (
  message: string,
  showNotification: (value: NotificationProviderProps) => void
) => {
  showNotification({
    iconName: 'arrow-up',
    variant: 'error',
    title: 'Error',
    message,
  });
};

export const showSuccessNotification = (
  message: string,
  showNotification: (value: NotificationProviderProps) => void
) => {
  showNotification({
    iconName: 'alert-circle',
    variant: 'success',
    title: 'Success',
    message,
  });
};
