import type { ReactNode } from 'react';
import { SnackbarProvider as Provider } from 'notistack';

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  return (
    <Provider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      {children}
    </Provider>
  );
};
