import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { axios } from '@/lib/axios';

import type { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import type { CurrentUser } from '../types';

type ResponseBody = {
  data: CurrentUser;
};

export const getCurrentUser = (): Promise<ResponseBody> => {
  return axios.get('/users/me');
};

type QueryFnType = typeof getCurrentUser;

type UseCurrentUserOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useCurrentUser = ({ config }: UseCurrentUserOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>, AxiosError>({
    ...config,
    queryKey: ['currentUser'],
    queryFn: getCurrentUser,
  });
};
