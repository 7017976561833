import { TOP_MENU_HEIGHT, TOP_MENU_HEIGHT_MOBILE } from '@/constants/layout';
import { Box, SxProps, type Theme, useMediaQuery } from '@mui/material';
import { Typography, theme, Icon, IconButton, Avatar } from '@commercefoundation/fractal';
import { ReactNode, useMemo } from 'react';
import { useCurrentUser } from '@/features/auth';
import { getInitials } from '@/helpers/stringHelper';
import { Link } from 'react-router-dom';
import FoundationLogo from '@/assets/images/foundation_logo.svg';

type SellerHeaderProps = {
  title: string | ReactNode;
  subTitle?: string;
  actions?: ReactNode[];
  isHome?: boolean;
  sx?: SxProps;
};

export const SellerHeaderContainer = ({ children }: { children?: ReactNode }) => (
  <Box
    maxHeight={{
      mobile: TOP_MENU_HEIGHT_MOBILE,
      desktop: TOP_MENU_HEIGHT,
    }}
    minHeight={{
      mobile: TOP_MENU_HEIGHT_MOBILE,
      desktop: TOP_MENU_HEIGHT,
    }}
    borderBottom={`1px solid ${theme.palette.gray['200']}`}
    display="flex"
    px={{ mobile: '1rem', tablet: '2rem' }}
    flex={1}
    width="100%"
    alignItems="center"
    position="sticky"
    top={0}
    bgcolor="white"
    zIndex={100}
    boxShadow={{
      mobile: theme.shadow.sm,
      tablet: 'none',
    }}
  >
    {children}
  </Box>
);

export const SellerHeader = ({ title, subTitle, actions, isHome, sx }: SellerHeaderProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('tablet'));

  const { data: currentUser } = useCurrentUser();

  const fullName = currentUser?.data?.full_name;

  const initials = useMemo(() => {
    if (!fullName) return '';

    return getInitials(fullName, 2);
  }, [fullName]);

  return (
    <SellerHeaderContainer>
      <Box
        flex={1}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ ...sx }}
      >
        {isMobile &&
          (isHome ? (
            <img src={FoundationLogo} alt="Foundation" width="140px" height="25px" />
          ) : (
            <IconButton variant="tertiary" onClick={() => window.history.back()}>
              <Icon name="arrow-left" size="xs" color={theme.palette.primary['600']} />
            </IconButton>
          ))}

        {!isHome && (
          <Box display="flex" flexDirection="column" gap="4px">
            {typeof title === 'string' ? (
              <Typography
                component="h1"
                fontSize={isMobile ? 'lg' : 'xl'}
                fontWeight="semibold"
                color={theme.palette.gray['900']}
              >
                {title}
              </Typography>
            ) : (
              title
            )}
            {subTitle && (
              <Box display={{ mobile: 'none', desktop: 'block' }}>
                <Typography component="h3" color={theme.palette.gray['600']}>
                  {subTitle}
                </Typography>
              </Box>
            )}
          </Box>
        )}

        {isMobile ? (
          <Link to="/ui/admin">
            <Avatar size="md" sx={{ bgcolor: theme.palette.primary['100'] }}>
              {initials}
            </Avatar>
          </Link>
        ) : (
          <Box display="flex" flexDirection="row" gap="12px">
            {actions}
          </Box>
        )}
      </Box>
    </SellerHeaderContainer>
  );
};
