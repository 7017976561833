import type { AxiosError } from 'axios';
import {
  QueryClient,
  type UseQueryOptions,
  type UseMutationOptions,
  type DefaultOptions,
} from '@tanstack/react-query';
import type { PromiseValue } from 'type-fest';

const onErrorForQueries = (error: AxiosError) => {
  const status = error.response?.status;

  switch (status) {
    case 401:
      window.location.assign('/401');
      break;
    case 403:
      window.location.assign('/403');
      break;
    case 404:
      // TODO: Show NotFound error msg
      break;
    case 500:
      // Use Error Boundary
      break;
    default:
      // Use Error Boundary
      break;
  }
};

const queryConfig: DefaultOptions<AxiosError> = {
  queries: {
    suspense: true,
    useErrorBoundary: (error: AxiosError) => {
      const status = error?.response?.status;
      if (!status) return true;
      return status >= 500;
    },
    refetchOnWindowFocus: false,
    retry: false,
    onError: onErrorForQueries,
    keepPreviousData: true,
  },
};

export const queryClient = new QueryClient({
  // @ts-ignore
  defaultOptions: queryConfig,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ExtractFnReturnType<FnType extends (...args: any) => any> = PromiseValue<
  ReturnType<FnType>
>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type QueryConfig<QueryFnType extends (...args: any) => any> = Omit<
  UseQueryOptions<ExtractFnReturnType<QueryFnType>, AxiosError>,
  'queryKey' | 'queryFn'
>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MutationConfig<MutationFnType extends (...args: any) => any> = UseMutationOptions<
  ExtractFnReturnType<MutationFnType>,
  AxiosError,
  Parameters<MutationFnType>[0] | void
>;
