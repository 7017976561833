import chmln from '@chamaeleonidae/chmln';
import { queryClient } from '@/lib/react-query';
import { getCurrentUser } from '@/features/auth';

export async function initChmln() {
  // @ts-ignore
  const token = document.getElementById('chameleon-token')?.content;
  if (!token) return;
  chmln.init(token, { fastUrl: 'https://fast.chameleon.io/' });

  const { data } = await queryClient.ensureQueryData({
    queryKey: ['currentUser'],
    queryFn: getCurrentUser,
  });

  const userId = data?.id;
  const fullName = data?.full_name;

  if (userId && fullName)
    chmln.identify(userId, {
      name: fullName,
      product: 'seller-portal',
      host: window.location.host,
    });
}
