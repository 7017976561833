import * as yup from 'yup';

export const SignUpSchema = yup.object().shape({
  full_name: yup
    .string()
    .required('Full name is required')
    .min(3, 'Full name must be at least 3 characters'),
  email: yup.string().email('Invalid email').required('Email is required'),
  company_name: yup
    .string()
    .required('Company name is required')
    .min(3, 'Company name must be at least 3 characters'),
  business_type: yup.string().required('Business type is required'),
});

export const CheckEmailSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
});

export const PasswordFormSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters'),
});

export const ResetPasswordEmailFormSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
});

export const ResetPasswordFormSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters'),
  password_confirmation: yup.string().required('Password confirmation is required'),
});

export const UserSetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters')
    .max(40, 'Password must not exceed 40 characters'),
  password_confirmation: yup
    .string()
    .required('Confirm Password is required')
    .oneOf([yup.ref('password')], 'Confirm Password does not match'),
});
