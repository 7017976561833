import Axios, { type AxiosError } from 'axios';
import { isTest } from '@/utils/environment';
import { API_ENDPOINT, TEST_ORIGIN } from '@/constants/api';

const origin = isTest() ? TEST_ORIGIN : window.origin;

export const axios = Axios.create({
  baseURL: origin + API_ENDPOINT,
});

axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);
